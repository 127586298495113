import React from 'react';

import { createStyles, WithStyles, withStyles } from '@material-ui/styles';

const styles = () => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shortName: {
    fontSize: '26vw',
    color: 'rgba(77, 75, 83, 0.25)',
  },
  fullName: {
    fontSize: '7.5vw',
    width: 'max-content',
    position: 'absolute',
    maxWidth: '80vw',
    textAlign: 'center',
  },
  description: {
    fontFamily: 'Inder, sans-serif',
    fontSize: '2.5vw',
  },
  nameContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export interface BusinessCardInfoProps extends WithStyles<typeof styles> {
  fullName: string;
  description: string;
  shortName: string;
}

const BusinessCardInfo: React.FC<BusinessCardInfoProps> = (props) => {
  const {
    classes,
    shortName,
    fullName,
    description,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.nameContainer}>
          <span className={classes.shortName}>{shortName}</span>
          <div className={classes.fullName}>{fullName}</div>
        </div>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(BusinessCardInfo);
