import React from 'react';
import ReactDOM from 'react-dom';

import { createGenerateClassName, StylesProvider } from '@material-ui/styles';

import { App } from './app';

const generateClassName = createGenerateClassName({
  productionPrefix: 'dk-',
});

const render = (Component: React.ReactNode): void => {
  ReactDOM.render(
    <StylesProvider generateClassName={generateClassName}>
      {Component}
    </StylesProvider>,
    document.getElementById('root'),
  );
};

render(<App />);
