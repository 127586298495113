import React from 'react';

import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { BusinessCardInfo } from './BusinessCardInfo';
import { BusinessCardLinks } from './BusinessCardLinks';

const styles = () => createStyles({
  root: {
    display: 'flex',
    height: '100%',

    '@media (orientation: portrait)': {
      flexDirection: 'column',
    },
  },
});

export interface BusinessCardProps extends WithStyles<typeof styles> { }

const BusinessCard: React.FC<BusinessCardProps> = ({ classes }) => (
  <div className={classes.root}>
    <BusinessCardInfo
      fullName="Dzianis Kramziuk"
      shortName="DK"
      description="Unity/ReactJS Developer"
    />
    <BusinessCardLinks />
  </div>
);

export default withStyles(styles)(BusinessCard);
