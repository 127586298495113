import React from 'react';

import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { IconLink } from './IconLink';
import links from '../../../config/links.json';

const styles = () => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(90, 90, 130, 0.1)',
    width: '20%',
    height: '100%',
    padding: '40px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',

    '@media (orientation: portrait)': {
      width: '100%',
    },
  },
  image: {
    width: '3vw',
    transition: 'transform 0.2s',

    '&:hover': {
      transform: 'scale(1.2)',
    },

    '@media (orientation: portrait)': {
      width: '12vw',
    },
  },
  imageWrap: {
    '&:not(:last-child)': {
      marginBottom: 30,
    },
  },
});

export interface BusinessCardLinksProps extends WithStyles<typeof styles> { }

const BusinessCardLinks: React.FC<BusinessCardLinksProps> = ({ classes }) => (
  <div className={classes.root}>
    {links.map((x) => (
      <IconLink
        key={x.id}
        iconSrc={x.iconSrc}
        url={x.url}
        alt={x.alt}
        classes={{ image: classes.image, root: classes.imageWrap }}
      />
    ))}
  </div>
);

export default withStyles(styles)(BusinessCardLinks);
