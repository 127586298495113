import React from 'react';

import { createStyles, withStyles } from '@material-ui/styles';

import { BusinessCard } from '../BusinessCard';

const styles = () => createStyles({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    a: {
      textDecoration: 'none',
    },
    html: {
      height: '100%',
    },
    body: {
      color: 'white',
      fontFamily: 'Gruppo, cursive',
      // backgroundColor: '#12152a',
      backgroundColor: 'black',
      height: '100%',
      margin: 0,

      '&>div': {
        height: '100%',
      },
    },
  },
});

const App: React.FC = () => {
  // eslint-disable-next-line no-console
  console.log('AnJ <3');
  // eslint-disable-next-line no-console
  console.log('Кто такая Ванесса Рысь?');

  return <BusinessCard />;
};

export default withStyles(styles)(App);
