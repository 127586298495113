import React from 'react';

export interface IconLinkProps {
  iconSrc: string;
  url: string;
  alt?: string;
  classes?: { root?: string, image?: string };
}

const IconLink: React.FC<IconLinkProps> = (props) => {
  const {
    iconSrc,
    url,
    alt,
    classes,
  } = props;

  return (
    <a className={classes && classes.root} href={url} target="_blank" rel="noreferrer">
      <img className={classes && classes.image} src={iconSrc} alt={alt} />
    </a>
  );
};

export default IconLink;
